import { useState } from 'react';
import cn from 'classnames';
import Head from 'next/head';

import { useProfilesContext } from '@/openMarket/application/Finsight/ProfilesContext';
import useIsMobile from '@/Framework/hooks/useIsMobile';
import ProfileItem from './ProfileItem';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import SearchInput from '@/Framework/UI/Organisms/FinalForm/Fields/SearchInput';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import IconList from '@dealroadshow/uikit/core/components/Icon/IconList';
import IconTiles from '@dealroadshow/uikit/core/components/Icon/IconTiles';
import InfiniteScroll from '@dealroadshow/uikit/core/components/InfiniteScroll';
import MissingSomethingModalButton from '@/finsight/ui/common/components/MissingSomethingModalButton';
import { isScreenS } from '@dealroadshow/uikit/core/styles/screen/screen';
import { ViewType } from '@/openMarket/application/Finsight/ProfilesContext/constants';
import { getPageTitle } from '@/finsight/ui/common/helpers';
import { getDecodedProfile } from './helper';

import templateStyles from '@/finsight/ui/components/static/StaticTemplate/staticTemplate.scss';
import styles from './profiles.scss';

const Profiles = () => {
  const isMobile = useIsMobile(isScreenS);
  const [viewType, setViewType] = useState(ViewType.GRID);

  const {
    loadMore,
    collection,
    isFetching,
    isFirstPageFetching,
    isNextPageFetching,
    totalCount,
    debouncedSearch,
    debouncedSearchString,
  } = useProfilesContext();

  return (
    <div className={ templateStyles.container }>
      <Head>
        <title>{ getPageTitle('OpenMarket Profiles') }</title>
      </Head>
      <div className={ styles.wrapper }>
        <div className={ styles.header }>
          <h2 className={ styles.title } data-test="pageHeaderTitle">
            Underwriters Profiles
          </h2>
          <div className={ styles.headerRightSide }>
            <SearchInput
              dataTest="searchInput"
              formFieldClassName={ styles.searchInput }
              value={ debouncedSearchString }
              onChange={ debouncedSearch }
              placeholder="Filter by name"
              isNarrow={ false }
            />
            <Button
              className={ cn(styles.viewTypeButton, { [styles.viewTypeButtonActive]: viewType === ViewType.LIST }) }
              variant={ ButtonVariantType.link }
              dataTest="listTypeButton"
              onClick={ () => setViewType(ViewType.LIST) }
            >
              <IconList />
            </Button>
            <Button
              className={ cn(styles.viewTypeButton, { [styles.viewTypeButtonActive]: viewType === ViewType.GRID }) }
              variant={ ButtonVariantType.link }
              dataTest="gridTypeButton"
              onClick={ () => setViewType(ViewType.GRID) }
            >
              <IconTiles />
            </Button>
          </div>
        </div>

        <div className={ styles.profilesWrapper }>
          <Spinner isAbsolute overlay isVisible={ isFirstPageFetching } />
          { collection.length > 0 ? (
            <InfiniteScroll
              fetchCallback={ loadMore }
              isFetching={ isFetching }
              scrollContainerRef={ typeof document !== 'undefined' ? document : null }
              isAllItemsFetched={ collection.length === totalCount }
              minimalScrollDistance={ 250 }
            >
              <div
                data-test="profilesList"
                className={ cn({
                  [styles.profilesGrid]: viewType === ViewType.GRID || isMobile,
                  [styles.profilesList]: viewType === ViewType.LIST && !isMobile,
                }) }
              >
                { collection.map((profile) => (
                  <ProfileItem
                    profile={ getDecodedProfile(profile) }
                    key={ profile.viewId }
                    viewType={ viewType }
                    isMobile={ isMobile }
                  />
                )) }
              </div>
              <Spinner isVisible={ isNextPageFetching } className={ styles.spinner } />
            </InfiniteScroll>
          ) : (
            <div className={ styles.zeroCase } data-test="profilesListEmpty">
              No profiles found.
            </div>
          ) }
        </div>
        <MissingSomethingModalButton className={ styles.missingSomething } />
      </div>
    </div>
  );
};

export default Profiles;
